import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["input", "suggestions", "categories", "locations", "cities", "creators", "searchFor"];

  connect() {
    document.addEventListener("click", (event) => {
      if (!this.element.contains(event.target)) {
        this.hideSuggestions();
      }
    });
  }

  suggestions(event) {
    if (event.keyCode == 224) {
      return;
    }

    const query = this.inputTarget.value;
    const url = this.element.dataset.suggestionsUrl;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.requestSuggestions(query, url);
    }, 500);
  }

  requestSuggestions(query, url) {
    if (query.length < 3) {
      this.hideSuggestions();
      return;
    }
    this.showSuggestions();

    query = query.toLowerCase();

    var categories = JSON.parse(document.getElementById('search-suggestions-data').dataset.categories)

    var categoriesResultsRow = this.categoriesTarget.querySelector(".flex-row")

    categoriesResultsRow.innerHTML = "";
    this.categoriesTarget.classList.add("hidden");

    Object.keys(categories).forEach((categoryKey) => {
      const category = categories[categoryKey];
      if (category.val.some((value) => value.startsWith(query))) {
        var span = document.createElement("a");

        span.className = "badges-list__item p-0.5";
        if (category.search) {
          span.href = `/search/${category.search}`;
        } else {
          span.href = `/en/${category.val[0]}-onlyfans-creators`;
        }
        span.style = 'padding: 0.5rem; margin: 0.25rem;'
        span.innerHTML = `<span>#${categoryKey}</span>` ;

        categoriesResultsRow.appendChild(span);
        this.categoriesTarget.classList.remove("hidden");
      }
    });

    var locations = JSON.parse(document.getElementById('search-suggestions-data').dataset.lcations)

    var locationsResultsRow = this.lcationsTarget.querySelector(".flex-row")

    locationsResultsRow.innerHTML = "";
    this.lcationsTarget.classList.add("hidden");

    Object.keys(locations).forEach((locationKey) => {
      const locationValues = locations[locationKey];
      if (locationValues.some((value) => value.startsWith(query))) {
        var span = document.createElement("a");

        span.className = "badges-list__item p-0.5";
        span.href = `/en/search-onlyfans/location/${locations[locationKey][0]}`;
        span.style = 'padding: 0.5rem; margin: 0.25rem;'
        span.innerHTML = `<span>#${locationKey}</span>` ;

        locationsResultsRow.appendChild(span);
        this.lcationsTarget.classList.remove("hidden");
      }
    });

    var cities = JSON.parse(document.getElementById('search-suggestions-data').dataset.cities)

    var citiesResultsRow = this.citiesTarget.querySelector(".flex-row")

    citiesResultsRow.innerHTML = "";
    this.citiesTarget.classList.add("hidden");

    Object.keys(cities).forEach((cityKey) => {
      const cityValues = cities[cityKey];
      if (cityValues.some((value) => value.startsWith(query))) {
        var span = document.createElement("a");

        span.className = "badges-list__item p-0.5";
        span.href = `/en/cities/${cities[cityKey][0]}`;
        span.style = 'padding: 0.5rem; margin: 0.25rem;'
        span.innerHTML = `<span>#${cityKey}</span>` ;

        citiesResultsRow.appendChild(span);
        this.citiesTarget.classList.remove("hidden");
      }
    });

    this.searchForTarget.href = "/search/" + query
    this.searchForTarget.querySelector(".search-for-text").innerHTML = query;

    // fetch(url, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content,
    //   },
    //   body: JSON.stringify({ query: query }),
    // }).then((response) => {
    //   response.json().then((html) => {
    //     const creatorsResultsRow = this.creatorsTarget.querySelector(".results-row");

    //     if (html.length != 0) {
    //       this.creatorsTarget.classList.remove("hidden");
    //       creatorsResultsRow.innerHTML = "";
    //     } else {
    //       this.creatorsTarget.classList.add("hidden");
    //     }

    //     html.forEach((item) => {
    //       const itemElement = document.createElement("a");
    //       itemElement.className = "p-2 border-b flex justify-start items-center rounded-lg group hover:bg-ofn hover:bg-opacity-50";
    //       itemElement.href = "/en/onlyfans/" + item.username;
    //       itemElement.innerHTML = `
    //         <img class="img mr-3 h-10 w-10 rounded-full border-none" src="${item.onlyfans_avatar_url}">

    //         <span class="block text-black">
    //           <span class="font-semibold mr-2">${item.name}</span>
    //           <span class="text-gray-600">${item.username}</span>
    //         </span>
    //       `;

    //       creatorsResultsRow.appendChild(itemElement);
    //     });
    //   });
    // });
  }

  childClicked(event) {
    this.childWasClicked = this.element.contains(event.target);
  }

  showSuggestions() {
    this.suggestionsTarget.classList.remove("hidden");
  }

  hideSuggestions() {
    if (!this.childWasClicked) {
      this.suggestionsTarget.classList.add("hidden");
    }
    this.childWasClicked = false;
  }
}
