import * as Turbo from "@hotwired/turbo"

# Sentry
#import * as Sentry from '@sentry/browser'
#import { Integrations } from "@sentry/tracing"
#
#Sentry.init({
#  dsn: "https://2acfd145ff8b479eaef171811167a51f@o980501.ingest.sentry.io/6172959",
#  integrations: [new Integrations.BrowserTracing()],
#  tracesSampleRate: 1.0,
#});

import importAll from "../utilities/importAll"

import ahoy from "ahoy.js";
window.ahoy = ahoy;

# Bootstrap
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/collapse'
import 'search_controllers'
import 'profile_top_video_controllers'

importAll(require.context("../common", true, /\.(js|jsx|coffee|js\.erb)$/))
