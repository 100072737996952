window.csrfToken = () => {
  return document.getElementsByName("csrf-token")[0].content;
}

window.isSignedIn = () => {
  return document.body.dataset.signedIn === 'true';
}

window.openAuthenticationModal = () => {
  return openModal2('#authenticationModal');
}