import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["imagePlaceholder"]

  load() {
    const iframe = document.createElement('iframe');
    iframe.allow = "autoplay; encrypted-media";

    const personalCode = this.imagePlaceholderTarget.dataset.personalVideoCode;

    if (personalCode) {
      iframe.src = `https://drive.google.com/file/d/${personalCode}/preview`;
    } else {
      iframe.src = "https://drive.google.com/file/d/1mZN0wWwBrWPdtIVw7trL4I5U1qQ3NL9T/preview";
    }

    iframe.style.position = 'absolute';
    iframe.style.top = '0';
    iframe.style.left = '0';
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.border = 'none';
    iframe.allowFullscreen = false;

    this.element.style.position = 'relative';
    this.element.style.paddingTop = '56.25%';
    this.element.style.overflow = 'hidden';

    this.element.innerHTML = '';
    this.element.appendChild(iframe);
  }
}