const markFavorited = ($toggleEl) => {
  $toggleEl.addClass('active');
}

const markUnfavorited = ($toggleEl) => {
  $toggleEl.removeClass('active');
}

window.initFavorite = () => {
  $('.js-favorite').on('click', function(e) {
    e.preventDefault();

    const $toggle = $(this)

    const username = $toggle.data('username');
    const creator_id = $toggle.data('creator-id');
    const isFavoriting = !$toggle.hasClass('active');

    if (!isSignedIn()) {
      openAuthenticationModal();
      trackEvent('favorite:not_signed_in', { username });
      return;
    }

    if (isFavoriting) {
      trackEvent('favorite:on', { username });
      markFavorited($toggle)
    } else {
      trackEvent('favorite:off', { username });
      markUnfavorited($toggle);
    }


    $.ajax({
      url: "/my/favorites",
      type: "POST",
      headers: {
        "X-CSRF-Token": csrfToken()
      },
      data: {
        username: username,
        creator_id: $toggle.data('creator-id'),
        favoriting: isFavoriting
      },
      success: (response) => {
        response.favorited ? markFavorited($toggle) : markUnfavorited($toggle);
      },
      error: (error) => {
        console.error("Error favoriting user", error);
        markUnfavorited($toggle);
      }
    });
  });
}

document.addEventListener('turbo:load', () => {
  initFavorite();
})
