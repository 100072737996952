document.addEventListener('turbo:load', () => {
  $('.js-modal2').on('click', (e) => {
    // Don't listen to clicks inside modal body
    if ($(e.target).closest('.modal2')[0])
      return;

    window.closeModal2();
  });

  $('.js-modal2-toggle').on('click', function (e) {
    e.preventDefault();
    const modalId = $(this).data('target') || $(this).attr('href');
    window.openModal2(modalId);
  });
})

window.openModal2 = (modalId) => {
  $('body').addClass('modal2-open');
  const $modalEl = $(".js-modal2" + modalId)

  if (!$modalEl[0]) return;

  if (!$(".js-modal2-backdrop")[0])
    $("body").append("<div class='modal2-backdrop js-modal2-backdrop'></div>");

  $modalEl.addClass('active');
  $modalEl.fadeIn();

  $('.js-modal2-backdrop').fadeIn('fast');
}

window.closeModal2 = () => {
  const $modals = $('.js-modal2');
  $('body').removeClass('modal2-open');
  $modals.fadeOut();
  $modals.removeClass('active');
  $('.js-modal2-backdrop').fadeOut('fast');
}
